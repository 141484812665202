<template>
  <div>
    <div class="row align-items-end">
      <div class="col">
        <label for="">Nom</label>
        <input type="text" v-model="section.fullName" class="form-control" />
      </div>
      <div class="col-3">
        <label for="">Dépôt <span class="text-danger">*</span> </label>
        <select v-model="section.deposit_reference" class="form-select">
          <option
            v-for="(deposit, index) in deposits"
            :key="index++"
            :value="deposit.reference"
          >
            {{ deposit.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Fournisseur <span class="text-danger">*</span> </label>
        <select v-model="section.supplier_reference" class="form-select">
          <option value=""></option>
          <option
            v-for="(supplier, index) in suppliers"
            :key="index++"
            :value="supplier.reference"
          >
            {{ supplier.society }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(section)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      section: {},
    };
  },
  computed: {
    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
    ...mapGetters("deposit", {
      deposits: "getAll",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("section/store", data);
    },
    async refresh() {
      this.$store.dispatch("supplier/getAll");
      this.$store.dispatch("deposit/getAll");
    },
  },
  beforeMount() {
    this.$store.dispatch("supplier/getAll");
    this.$store.dispatch("deposit/getAll");
  },
};
</script>
